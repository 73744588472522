import React from 'react';
import animationData from '../../../public/SA_icones_loading.json';
import Lottie from 'lottie-react';

const LoadingScreen: React.FC = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                zIndex: 9999,
            }}
        >
            <Lottie
                style={{ width: '250px', height: '250px', opacity: 0.8 }}
                animationData={animationData}
                autoPlay
                loop
            />
        </div>
    );
};

export default LoadingScreen;
